<template>
    <div class="p-card-head"　@click="viewDetail(id)">
        
        <h1 class="p-card-head__title" v-if="title">
            <router-link :to="{name:'post', params: { keyword:  id }}">{{ title }} {{ subtitle }} <span class="p-card-head__titlecaption">({{ date | moment("YYYY")}}年)</span></router-link>
        </h1>
        <span class="p-card-head__caption text-muted" v-if="furigana">{{ furigana }}</span>
        <!-- <span class="p-card-head__date" v-if="date">上演：{{ date | moment("YYYY年MM月DD日")}} <span class="d-none d-sm-inline">／ </span><br class="d-inline d-sm-none">{{ theater }}</span> -->
        
        <div class="p-card-body d-flex flex-row">
            <section class="p-card-body__col" v-if="dramatist">
                <h2 class="p-card-body__heading">作者</h2>
                <router-link :to="{name:'search', params: { keyword:  dramatist }}" tag="button" class="btn btn-sm btn-light">{{ dramatist }}</router-link>
            </section>
            <section class="p-card-body__col" v-if="director">
                <h2 class="p-card-body__heading">演出</h2>
                <router-link :to="{name:'search', params: { keyword:  director }}" tag="button" class="btn btn-sm btn-light">{{ director }}</router-link>
            </section>
            <section class="p-card-body__col p-card-body__col--translator" v-if="translator">
                <h2 class="p-card-body__heading">翻訳</h2>
                <router-link :to="{name:'search', params: { keyword:  translator }}" tag="button" class="btn btn-sm btn-light">{{ translator }}</router-link>
            </section>
            <section class="p-card-body__col" v-if="organizer">
                <h2 class="p-card-body__heading">制作・主催</h2>
                <router-link :to="{name:'search', params: { keyword:  organizer }}" tag="button" class="btn btn-sm btn-light">{{ organizer }}</router-link>
            </section>

        </div>
        
    </div>

</template>
<script>
import moment from "moment";

export default {
    props: {
        id: {
            type: String,
        },
        title: {
            type: String,
            requidred: true
        },
        subtitle: {
            type: String,
        },
        furigana: {
            type: String,
        },
        date: {
            type: String,
        },
        theater: {
            type: String,
        },
        dramatist: {
            type: String,
        },
        translator: {
            type: String,
        },
        organizer: {
            type: String,
        },
        director: {
            type: String,
        }

    },
    filters: {
        /**
         * @param {Date} value    - Date オブジェクト
         * @param {string} format - 変換したいフォーマット
         */
        moment(value, format) {
            return moment(value).format(format);
        }
    },
    methods:{
        //省略記法
        viewDetail: function(param){
            this.$router.push({ name: 'post', params: { keyword: param } })
        }
    }
}
</script>